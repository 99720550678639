
import {
  Component, Watch, Vue,
} from 'vue-property-decorator'
import { mapState } from 'vuex'
import _ from 'lodash'
import store from '@/store'
import {
  l, localeFromPath, formatDate, imagePath,
} from '@/utils/helpers'

import TableOfContents from '@/components/atoms/TableOfContents.vue'
import FourOhFour from '@/components/molecules/FourOhFour.vue'
import Hero from '@/components/molecules/Hero.vue'
import Persons from '@/components/molecules/Persons.vue'
import Organisations from '@/components/molecules/Organisations.vue'
import ContentSection from '@/components/organisms/ContentSection.vue'
import Button from '@/components/atoms/Button.vue'
import TranslatePost from '@/components/atoms/TranslatePost.vue'
import Date from '@/components/atoms/Date.vue'
import Label from '@/components/atoms/Label.vue'
import ScrollUp from '@/components/atoms/ScrollUp.vue'
import SocialShare from '@/components/molecules/SocialShare.vue'

import conf from '../../configuration'

const head = ''

@Component({
  components: {
    TableOfContents,
    FourOhFour,
    Hero,
    ContentSection,
    Button,
    Date,
    ScrollUp,
    SocialShare,
    Persons,
    Organisations,
    Label,
    TranslatePost,
  },
  // This is used by the Vue Meta package
  // to add dynamic meta information to HTML head
  // See also public/index.html
  metaInfo() {
    const {
      title, description, image, imageAlt, locale,
    } = this.$store.getters.activePost
    return {
      title,
      meta: [
        { name: 'description', content: description },

        // Open graph
        { property: 'og:title', content: title || conf.rssTitle },
        { property: 'og:description', content: description || conf.rssDescription },
        { property: 'og:url', content: window.location.href },
        {
          property: 'og:image',
          content: image ? `${conf.media.baseUrl}${conf.media.sourcePath}${conf.media.namespace}${_.startsWith(image, '/') ? image : `/${image}`}` : conf.rssFeedImage,
        },
        {
          property: 'og:image:alt',
          content: imageAlt || 'earth',
        },

        // Twitter
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:summary', content: description },
        { property: 'twitter:title', content: title || conf.rssTitle },
        { property: 'twitter:description', content: description || conf.rssDescription },
        { property: 'twitter:url', content: window.location.href },
        {
          property: 'twitter:image',
          content: image ? `${conf.media.baseUrl}${conf.media.sourcePath}${conf.media.namespace}${_.startsWith(image, '/') ? image : `/${image}`}` : conf.rssFeedImage,
        },
        {
          property: 'twitter:image:alt',
          content: imageAlt || 'earth',
        },
      ],
      htmlAttrs: {
        lang: locale,
      },
      // Remove the /en/ part from the canonical URL if the language is English
      // Make sure canonical URLs always end with a /
      link: [
        {
          rel: 'canonical',
          href: locale === 'en' && !this.$route.path.includes('/en/')
            ? `${conf.url}${_.replace(_.endsWith(this.$route.path, '/') ? this.$route.path : `${this.$route.path}/`, '/', '/en/')}`
            : `${conf.url}${_.endsWith(this.$route.path, '/') ? this.$route.path : `${this.$route.path}/`}`,
        },
      ],
    }
  },
  computed: mapState({
    contentNotFound: (state: any) => (
      state.status === 404
    ),
    post: (state: any) => (
      (state.active && state.active.post) ? state.active.post.post : undefined
    ),
    postTopics: (state: any) => (
      (state.active && state.active.post) ? state.active.post.topics : []
    ),
    postTags: (state: any) => (
      (state.active && state.active.post) ? state.active.post.tags : []
    ),
  }),
  methods: {
    l,
    formatDate,
    handleOnEmbedClick() {
      // console.log('Embed button clicked')
    },
    getRelatedContentIds(post:any) {
      if (post) {
        return _.compact(
          _.concat(
            post.relatedGuides,
            post.relatedFilms,
            post.relatedInterviews,
            post.relatedContent,
            post.relatedResources,
            post.relatedDatabases,
            post.relatedTools,
            post.relatedWorkshops,
          ),
        )
      }
      return []
    },
    getScrollImage() {
      return imagePath(conf.scrollUpImage, 16)
    },
  },
})
export default class Post extends Vue {
  constructor() {
    super()
    this.locale = ''
    this.isLoading = true
  }

  private locale: string

  private isLoading: boolean

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.locale = localeFromPath(this.$route.path || '/')
    if (this.$route && this.$route.params && this.$route.params.slug) {
      try {
        store.dispatch('fetch', `/?slug=${this.$route.params.slug}&&locale=${this.locale}`)
          .then((result: any) => {
            if (result && result[0]) {
              // Set the fetched post content as active post in the VUEX store
              try {
                store.dispatch('set', { key: 'active.post.post', data: result[0] })
              } catch (err) {
                document.dispatchEvent(new Event('custom-render-trigger'))
              }
              if (this.$route.hash) {
                const elToScrollTo = document.getElementById(this.$route.hash.substring(1))
                if (elToScrollTo) elToScrollTo.scrollIntoView()
              }
              return result[0]
            }
            return undefined
          })
          .then(() => {
            if (_.isEmpty(this.$store.getters.topics)) {
              store.dispatch(
                'fetch',
                `/?contentType=topic&&locale=${this.locale}`,
              )
                .then((result: any) => {
                  if (result) {
                    try {
                      // Set all Topics in the VUEX store
                      store.dispatch('set', { key: 'content.topics', data: result })
                    } catch (err) {
                      document.dispatchEvent(new Event('custom-render-trigger'))
                    }
                    return result
                  }
                  return undefined
                })
            }
            try {
              store.dispatch(
                'set',
                { key: 'active.post.topics', data: this.$store.getters.topicsFromActivePostContent },
              )
            } catch (err) {
              document.dispatchEvent(new Event('custom-render-trigger'))
            }
          })
          .then(() => {
            if (_.isEmpty(this.$store.getters.tags)) {
              store.dispatch(
                'fetch',
                `/?contentType=tag&&locale=${this.locale}`,
              )
                .then((result: any) => {
                  if (result) {
                    try {
                      // Set all Tags in the VUEX store
                      store.dispatch('set', { key: 'content.tags', data: result })
                    } catch (err) {
                      document.dispatchEvent(new Event('custom-render-trigger'))
                    }
                    return result
                  }
                  return undefined
                })
            }
            try {
              store.dispatch(
                'set',
                { key: 'active.post.tags', data: this.$store.getters.tagsFromActivePostContent },
              )
            } catch (err) {
              document.dispatchEvent(new Event('custom-render-trigger'))
            }
            this.isLoading = false
            document.dispatchEvent(new Event('custom-render-trigger'))
          })
          .catch(
            (error: string) => {
              try {
                store.dispatch('set', { key: 'status', data: 404 })
              } catch (err) {
                setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 5000)
              }
              setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 5000)
            },
          )
      } catch (err) {
        setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 5000)
      }
    }
  }
}
