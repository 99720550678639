

import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import _ from 'lodash'
import store from '@/store'
import { l } from '@/utils/helpers'

@Component({
  methods: {
    l,
  },
})

export default class TranslatePost extends Vue {
  @Prop() private locale!: string

  @Prop() private translationKey!: string

  constructor() {
    super()
    this.translations = []
  }

  private translations: Array<any>

  readonly languageNames = {
    es: 'Español',
    en: 'English',
    et: 'Eesti',
    lt: 'Lietuvių',
    lv: 'Latvieši',
    cs: 'Čeština',
    sk: 'Slovenčina',
    pl: 'Polski',
    hu: 'Magyar',
    it: 'Italiano',
    el: 'Ελληνικά',
    fr: 'Français',
  }

  @Watch('translationKey')
  onTranslationKeyChange(newTranslationKey: any, oldTranslationKey: any) {
    this.translationKey = newTranslationKey
    this.fetchData()
  }

  @Watch('locale')
  onLocaleChange(newLocale: any, oldLocale: any) {
    this.locale = newLocale
    this.fetchData()
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.fetchData()
  }

  fetchData() {
    if (this.locale && this.translationKey) {
      // Set Translations
      try {
        store.dispatch('fetch', `/?translationKey=${this.translationKey}`)
          .then((translationsResult: any) => {
            if (translationsResult && translationsResult[0]) {
              // Set the fetched post content as active post in the VUEX store
              this.$nextTick(() => {
                this.translations = translationsResult
              })
              return translationsResult
            }
            this.$nextTick(() => {
              this.translations = []
            })
            return undefined
          })
      } catch (err) {
        setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 5000)
      }
    }
  }
}
